﻿@import "../../mixins.scss";

.block-help {
    display: flex;
    flex-direction: column;

    &__header {
        margin: 0;
        margin-bottom: 0.5rem;
        margin-left: 1.875rem;
        color: $text-color;
        @include text-pipe($sidebar: true);

        &::before {
        height: 52px !important;
        margin-bottom: -15px;
        z-index: 999;
        }
    }

    &__inner {
        display: flex;
        flex-direction: row-reverse;
        min-height: 180px;
        border-radius: 20px;
        border: 1px solid $border-color;
        background-color: $white;
        overflow: hidden;
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: 150px;
        @include media-breakpoint-down(xxl) {
            background-position-x: 100px;
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1.5rem 2.5rem;
        margin: 0;
        margin-right: 33%;
        color: #18214e;
        z-index: 999;
        min-width: 70%;
        p {
            margin: 0;
        }
    }

    &__image {
        margin-left: -60%;
        align-self: flex-end;
        object-fit: contain;
        object-position: bottom;
        max-width: 290px;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
        position: relative;
        right: -5px;
        @include media-breakpoint-down(xxl) {
            right: -30px;
            margin-left: -70%;
        }
    }
}