﻿@import "mixins.scss";

html, body {
    position: relative;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

h2, h3, h4, h5{
    margin-top: 2rem;
}

body {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $white;
    font-family: $font-family-base;
    font-size: $body-font-size;
    position: relative;

    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        display: none;
    }

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 572px;
        transform: translateX(-68.7%) skew(-8.7deg);
        background-color: $light-blue;
        border-top-right-radius: 130px;
        border-bottom-right-radius: 130px;
        z-index: -1;
        top: 0;
        background-repeat: no-repeat;
    }
}

body:not(.homepage) .body-section {
    padding-bottom: 3.5rem;
}

p:only-of-type,
p:last-of-type {
    margin: 0;
}

.screen-darken {
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .5);
    pointer-events: none;
    z-index: 10;
    opacity: 1;
    visibility: visible;
    position: fixed;
}

a {
    scroll-margin-top: 10rem;
}

.text-danger{
    color: $redish;
}
.text-success{
    color: $green;
}