﻿@import "../node_modules/bootstrap/scss/mixins";
@import "variables.scss";

/* 
    Custom mixins:
    escape-svg function is part of Bootstrap functions
*/

@mixin avatar($size, $circle: true) {
    width: $size;
    height: $size;

    @if $circle {
        border-radius: $size / 2;
    }
}

@mixin arrow-icon($color: '#000000', $width: 16px, $height: $width) {
    background-image: escape-svg(url("data:image/svg+xml,<svg viewBox='0 0 18.508 18.508' height='#{$height}' width='#{$width}' fill='#{$color}' xmlns='http://www.w3.org/2000/svg'><path d='M15.254,7,13.8,8.455l4.757,4.768H4v2.064H18.557L13.8,20.054l1.455,1.455,7.254-7.254Z' transform='translate(-4 -7)'/></svg>"));
    background-repeat: no-repeat;
    width: $width;
    height: $height;
}

@mixin arrow-icon-longer($color: '#000000', $width: 43px, $height: 14px) {
    background-image: escape-svg(url("data:image/svg+xml,%3Csvg height='#{$height}' width='#{$width}' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='#{$color}' stroke-width='2' fill='none' fill-rule='evenodd'%3E%3Cpath stroke-linecap='square' d='M1.5 7h39'/%3E%3Cpath d='m35.153 13 6-6-6-6'/%3E%3C/g%3E%3C/svg%3E%0A"));
    background-repeat: no-repeat;
    width: $width;
    height: $height;
}

@mixin arrow-step($color: '#000000', $width: 100px, $height: 60px) {
    background-image: escape-svg(url("data:image/svg+xml,<svg viewBox='0 0 100 46.77' width='#{$width}' fill='#{$color}' xmlns='http://www.w3.org/2000/svg'><path d='M20.72,17.39A77.74,77.74,0,0,1,42.13,3.7C49.55.6,56.75-.67,63.64.34c5.85.86,10.56,3.31,14,7.45a21.5,21.5,0,0,1,4.27,8.8,2.26,2.26,0,0,1-.83,2.21c-.78.69-1.55.8-2,.19-.82-1-1.59-2.11-2.38-3.17a32.24,32.24,0,0,0-5.09-5.54A19.14,19.14,0,0,0,60,6.07c-7.36-.44-14.71,1.77-22.11,5.86A80.29,80.29,0,0,0,19.47,26.06,88,88,0,0,0,5.73,44.15c-1,1.69-2,2.49-3.48,2.61C.3,46.93-.54,45.54.37,43.64a74.76,74.76,0,0,1,8-12.94A95.13,95.13,0,0,1,20.72,17.39Z'/><path d='M95.62,38.88c-.22-2-.4-4-.65-6A77.49,77.49,0,0,0,91.45,17c-.14-.39-.29-.77-.41-1.16a1.23,1.23,0,0,1,.65-1.62h0a1.3,1.3,0,0,1,1.64.63,35.42,35.42,0,0,1,4.18,10.44A138.85,138.85,0,0,1,100,40.9a2.11,2.11,0,0,1-1.63,2.48,2,2,0,0,1-.74,0,65.57,65.57,0,0,1-17.77-3.26,1.93,1.93,0,0,1-1.43-2.22,1.84,1.84,0,0,1,2.06-1.58h0c2.58.28,5.16.61,7.71,1,2.22.37,4.41.92,6.61,1.38C95.05,38.81,95.29,38.83,95.62,38.88Z'/></svg>"));
    background-repeat: no-repeat;
    width: $width;
    height: $height;
}

@mixin arrow-link($color: '#000000', $width: 43px, $height: 14px) {
    &::after {
        @include arrow-icon-longer($color: $color);
        content: '';
        display: inline-block;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        vertical-align: middle;
    }
}

@mixin caret-icon($color: '#000000', $width: 16px, $height: $width, $rotation: 0deg) {
    background-image: escape-svg(url("data:image/svg+xml,<svg viewBox='0 0 5.794 9.584' height='#{$height}' width='#{$width}' fill='#{$color}' style='transform: rotate(#{$rotation})' xmlns='http://www.w3.org/2000/svg'><path d='M12.168,16.573l3.855-3.837L12.168,8.921l.966-.966,4.828,4.781-4.828,4.8Z' transform='translate(-12.168 -7.955)'/></svg>"));
    background-repeat: no-repeat;
    width: $width;
    height: $height;
}

@mixin quote-icon($color: '#000000', $width: 16px, $height: $width) {
    background-image: escape-svg(url("data:image/svg+xml,<svg viewBox='0 0 110.977 94.799' height='#{$height}' width='#{$width}' xmlns='http://www.w3.org/2000/svg'><path d='M51.593-139.949a73,73,0,0,0-16.839,22.122q-5.943,12.216-5.943,22.452A16.47,16.47,0,0,1,36.074-96.7a19.619,19.619,0,0,1,14.858,6.273q5.943,6.273,5.943,15.848,0,10.566-6.769,17.169a23.366,23.366,0,0,1-17,6.6q-11.226,0-17.664-6.934T9-75.235q0-20.8,7.759-37.64T37.4-139.289q2.641-2.311,7.924-2.311Q49.612-141.6,51.593-139.949Zm59.1,0a73,73,0,0,0-16.839,22.122q-5.943,12.216-5.943,22.452A16.47,16.47,0,0,1,95.176-96.7a19.619,19.619,0,0,1,14.858,6.273q5.943,6.273,5.943,15.848,0,10.566-6.769,17.169a23.366,23.366,0,0,1-17,6.6q-11.226,0-17.664-6.934T68.1-75.235q0-20.8,7.759-37.64T96.5-139.289q2.641-2.311,7.924-2.311Q108.713-141.6,110.694-139.949Z' transform='translate(117.977 -48.802) rotate(180)' fill='none' stroke='#{$color}' stroke-width='4'/></svg>"));
    background-repeat: no-repeat;
    width: $width;
    height: $height;
}

@mixin phone-icon($color: '#000000', $width: 16px, $height: $width) {
    -webkit-mask: url("#{$icon-path}/phone.svg") center/cover no-repeat;
    mask: url("#{$icon-path}/phone.svg") center/cover no-repeat;
    background: $color;
    width: $width;
    height: $height;
    content: "";
}

@mixin renderIcon($icon-name, $color: '#000000', $width: 16px, $height: $width) {
    $icon: $icon-path + $icon-name + ".svg";
    -webkit-mask: url($icon) center/cover no-repeat;
    mask: url($icon) center/cover no-repeat;
    background: $color;
    width: $width;
    height: $height;
    content: "";
}

@mixin image-grayscale() {
    filter: grayscale(1);
    transform: translateZ(0);
    transition: filter ease-in-out 0.25s;

    &:hover,
    &:focus {
        filter: grayscale(0);
    }
}

@mixin card-shadow($offsetX: -20px, $offsetY: 20px, $width: 100%, $height: 100%) {
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        background: $secondary;
        transform: translate($offsetX, $offsetY);
        position: absolute;
        display: block;
        width: $width;
        height: $height;
        z-index: -1;
    }
}

@mixin gradient-overlay($from, $to, $deg: 0, $start: 0%, $stop: 100%, $opacity: 100%, $width: 100%, $height: 100%) {
    position: relative;

    &::before {
        content: "";
        background: linear-gradient($deg, $from $start, $to $stop);
        position: absolute;
        display: block;
        width: $width;
        height: $height;
        z-index: 2;
        opacity: $opacity;
        transition: all 0.25s ease-in-out;
    }
}

@mixin color-overlay($color, $alpha: 0.5, $width: 100%, $height: 100%) {
    position: relative;

    &::before {
        content: "";
        background: rgba($color, $alpha);
        position: absolute;
        display: block;
        width: $width;
        height: $height;
        z-index: 2;
        transition: all 0.25s ease-in-out;
    }
}

@mixin button-graphic($color: $yellow, $shadeColor: darken($color, 10%), $width: 100px, $height: 30px) {
    background-image: escape-svg(url("data:image/svg+xml,<svg viewBox='0 0 100 30.2' height='#{$height}' width='#{$width}' xmlns='http://www.w3.org/2000/svg'><path d='M100,0,2.25,13.32,0,.24Z' fill='#{$color}'/><path d='M2.28,13.14,60.86,30.2V5.29Z' fill='#{$shadeColor}'/></svg>"));
    background-repeat: no-repeat;
    background-position: center bottom;
    text-align: center;
    width: $width;
    height: $height;
}

@mixin gradient-animation($start, $end, $transition, $stop: 100%, $opacity: 1) {
    position: relative;

    &::before {
        background-image: linear-gradient(to top, $end $stop, $start);
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        z-index: 5;
        transition: $transition;
    }

    &:hover {
        &:before {
            opacity: $opacity;
        }
    }
}

@mixin text-pipe($color: $secondary, $width: 2px, $height: 45px, $x: -20px, $sidebar: false) {
    padding-left: 1rem;
    position: relative;

    &::before {
        content: '';
        display: inline-block;
        background-color: $color;
        width: $width;
        height: $height;
        position: relative;
        left: -1rem;
        top: 0.75rem;
    }

    @if $sidebar {
        transform: translateX(0);

        &::before {
            margin-bottom: -20px;
            height: 52px;
            position: absolute;
            left: 0;
            top: auto;
        }
    }
    @else {
        transform: translateX($x);
    }
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
    // For Google Chrome
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $foreground-color;
    }

    &::-webkit-scrollbar-track {
        background: $background-color;
    }
    // For Internet Explorer
    & {
        scrollbar-face-color: $foreground-color;
        scrollbar-track-color: $background-color;
    }
}