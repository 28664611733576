﻿@import "../../mixins.scss";

.block-icon-boxes {
    display: flex;
    position: relative;

    @include media-breakpoint-down(md) {
       text-align: center;
    }

    &-row {
        z-index: 2;
    }

    @include media-breakpoint-up(md) {
        .block-icon-boxes-row > div:not(:nth-last-of-type(-n+2)) {
            margin-bottom: 2rem;
        }
    }
    @include media-breakpoint-down(md) {
        .block-icon-boxes-row > div:not(:last-of-type) {
            margin-bottom: 2rem;
        }
    }


    @include media-breakpoint-up(md) {
        &-row {
            padding: 0 0 0 100px;
        }
        &::after {
            content: '';
            display: block;
            position: absolute;
            transform: translateX(0) skew(-8.7deg);
            width: 55vw;
            height: auto;
            min-height: 710px;
            background-color: $light-gray;
            border-top-left-radius: 140px;
            border-bottom-left-radius: 140px;
            z-index: 1;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-repeat: no-repeat;
        }
    }


    @include media-breakpoint-up(xl) {
        &::after {
            top: -100px;
        }
    }

    .block-icon-box {

        &__link {
            color: inherit;
            text-decoration: none;

            &:hover {
                .block-icon-box__icon {
                    background-color: rgba($dark-blue, 0.15);
                }
            }
        }

        &__icon {
            display: inline-block;
            padding: 16px;
            margin-bottom: 10px;
            border-radius: 50%;
            background-color: $white;
            transition: $btn-transition;

            > .icon {
                display: inline-block;

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &__header {
            margin-bottom: 0;
        }
    }
}