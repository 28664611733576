.calendar {
    &__header {
        margin-bottom: .5rem;
        font-weight: bold;
        margin-top: 0;
    }
    &__table {
        tr {
            background-color: $white;
            border: 1px solid $light-gray;

            &:hover {
                background-color: $dark-blue;
                color: $white;
                cursor: pointer;

                .calendar__table--name {
                    > div > span {
                        text-decoration: underline;
                    }
                }
            }
        }
        td {
            vertical-align: middle;
        }
        &--date {
            width: 5%;
            &-month{
                display: none;
            }
        }
        &--duration {
            width: 10%;
        }
        &--name {
            width: 40%;
            text-align: left;
            padding: 0;

            > div {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                padding-left: 1rem;
                position: relative;

                > span {
                    display: inline-block;
                }
            }

            .availability {
                transform: translate(-0.5rem, 0.5rem);
                position: absolute;
                left: 0;
                top: 0;
                width: 10px;
                height: 10px;
                display: inline-block;
                border-radius: 50%;

                &--available {
                    background-color: $green;
                }
                &--half {
                    background-color: $orange;
                }
                &--full {
                    background-color: $redish;
                }
            }
            
        }
        &--price {
            width: 15%;
        }
    }

    @include media-breakpoint-down(md){
        tr{
            display: flex;
            flex-direction: column;
            border: 1px solid $light-gray;
            margin-bottom: 2rem;

            td{
                width: 100%;
                border: none;
            }
            .calendar__table--name{
                padding-left: 1rem;
            }
            .calendar__table--date{
                display: flex;
                &-month{
                    margin-left: .5rem;
                    display: block;
                }
            }
        }
    }
}