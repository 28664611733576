.language-link-archive{

}
.language-links{
    padding: 0;
    list-style: none;

    &__item{
        &--link{
            text-decoration: none;
            padding: 1rem;
            background-color: $light-gray;
            margin-bottom: .5rem;
            border: 1px solid #fff;
            border-radius: 13px;
            width: 100%;
            display: block;
            color: $dark-blue;
            &:hover{
                background-color: #b7b9bb;
            }
        }
    }
}