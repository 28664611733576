.teacher-card {

    body.teacher & {
        min-width: 300px;
    }

    .block-card__content {
        padding: 1.5rem 1.5rem;
    }
}

.teacher-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 1.875rem;
    grid-row-gap: 1.875rem;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    .teacher-card {
        min-width: auto;

        .block-card__text {
            margin-top: 1rem;
            min-height: 60px;
        }
    }
}