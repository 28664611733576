.course-program {
    h2 {
        @include text-pipe();
    }

    padding-top: 0;
    
    &__item {
        margin-bottom: 2rem;
        padding: 1rem;
        background-color: #eff4f7;
        border-radius: 20px;

        &--header{
            margin-top: 0 !important;
        }

        &--teacher {
            display: flex;
            margin-top: 1rem;

            &__content {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                margin-left: 1rem;
            }
        }
    }
}