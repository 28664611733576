﻿@import "../../mixins.scss";

.block-pre-test {

    @include media-breakpoint-up(md) {
        .homepage & {
            margin-left: 1.875rem;
        }
    }   

    @include media-breakpoint-down(md) {
        text-align: center;
        margin-left: 0;

        .btn{
            width: 80%;
            margin-right: 0;
            margin-left: 0;
        }
    }

    &:hover {
        text-decoration: none;
        color: initial;
    }

    &__header {
        color: $text-color;

        @include text-pipe();
    }

    &__inner {
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background-color: $white;
    }

    &__text {
        color: $text-color;
        z-index: 2;
    }
    &__links {
        margin-top: 1rem;
    }
}

.block-list-sidebar {
    .block-pre-test {
        &__inner {
            border: 1px solid $border-color;
            padding: 1.5rem 2.5rem;
        }

        &__header {
            margin: 0;
            margin-bottom: 0.5rem;
            margin-left: 1.875rem;
            color: $text-color;
            @include text-pipe($sidebar: true);
        
            &::before {
                height: 52px !important;
                margin-bottom: -15px;
                z-index: 999;
            }
        }
        &__links{
            display: flex;
            a{
                flex-grow: 1;
            }
        }
    }
}