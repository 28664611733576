﻿@import "../../mixins.scss";

// Spacing between block items
.block-list {

    &__item {
        > * {
            padding-top: 2rem;
            padding-bottom: 2rem;
            position: relative;
        }

        &.full-width {
            > div {
                margin-left: -9999px !important;
                margin-right: -9999px !important;
                padding-left: 9999px;
                padding-right: 9999px;
                padding-top: 5rem !important;
                padding-bottom: 5rem !important;
            }
        }
    }

    @include media-breakpoint-down(md) {
        .block-column.row, .card-grid-row {
            gap: 3.5rem;
        }
    }

    &-main{
        h3,
        h4,
        h5 {
          margin-top: 1.5rem;
          margin-bottom: 0rem;
        }
    }
}

.sidebar {
    .block-list {
        &__item > * {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
    }
    @include media-breakpoint-down(xl) {
        display: none;
    }
}

.block a.btn {

    @include media-breakpoint-up(md) {
        &:not(:last-child) {
            margin-right: 0.875rem;
        }
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 0.875rem;
    }
}