﻿$font-family-base: 'Poppins', Arial, Helvetica, sans-serif;
$headings-font-family: 'Poppins', Arial, Helvetica, sans-serif;

$enable-responsive-font-sizes: true;

$sidebar-width: 390px;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$lighter-gray: #F2F6F8;
$light-gray: #EFF4F7;

$light-blue: #f0f4f8;
$dark-gray: #343333;
$dark-blue: #18214E;
$redish: #B52E48;
$yellow: yellow;
$green: #33b77d;
$orange: #ec4b00;


$primary: $dark-blue;
$secondary: $redish;
$secondary-hover: #8D1D32;

$text-color: $dark-blue;
$text-color-contrast: $white;

$gray-base: #525148;
$body-bg: $white;
$body-color: $dark-blue;
$body-font-size: 16px;
$line-height-base: 1.625;

$colors: (
    "dark-gray": $dark-gray,
    "dark-blue": $dark-blue,
    "light-blue": $light-blue,
    "redish": $redish
);

$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "gray": $gray-base,
    "dark": $black
);

$box-shadow-base: 0 10px 15px 3px rgba(#000, 0.3);

$component-active-color: $white !default;
$component-active-bg: $primary !default;

$font-size-large: 1.1rem;
$font-size-base: 1rem;
$font-size-small: 0.9rem;
$font-size-extra-small: 0.8rem;

$h1-font-size: 3rem;
$h2-font-size: 2.125rem;
$h3-font-size: 1.563rem;
$h4-font-size: 1.313rem;
$h5-font-size: 1rem;

$font-weight-base: 400;
$font-weight-semi-bold: 500;

$headings-font-weight: $font-weight-semi-bold;
$headings-margin-bottom: 1.25rem;

$lead-font-size: 1.5rem;

$block-grid-block-padding: 3rem;

$border-radius: 0px;
$border-radius-sm: 0px;
$border-radius-lg: 0px;

$link-color: darken($redish, 5%) !default;
$link-decoration: none !default;
$link-shade-percentage: 20% !default;
$link-hover-color: shift-color($link-color, $link-shade-percentage) !default;
$link-hover-decoration: underline !default;

$input-bg: $white !default;
$input-disabled-bg: $gray-200 !default;

$input-btn-font-size:  0.9375rem !default;
$input-btn-line-height: 1.2 !default;
$input-btn-focus-width:       .25rem !default;
$input-btn-focus-color:       rgba($component-active-bg, .25) !default;
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-color: $gray-700 !default;
$input-border-color: #D4D4D4 !default;
$input-border-width: 1px !default;
$input-border-radius: 36px !default;

$input-padding-y: 0.75rem;
$input-padding-x: 1rem;
$input-box-shadow: none;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: tint-color($component-active-bg, 50%) !default;
$input-focus-color: $input-color !default;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$btn-font-size: 1rem;
$btn-font-weight: 500;
$btn-line-height: $input-btn-line-height;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping
$btn-padding-y: 1rem;
$btn-padding-x: 2rem;
$btn-padding-y-sm: 0.5rem;
$btn-padding-x-sm: 2rem;

$btn-border-radius: 25px;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);


$grid-gutter-width: 1.5rem;
$gutters: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

$header-height: 90px;
$mobile-header-height: 60px;

// Navigation color
$navbar-light-color: $body-color;
$navbar-light-hover-color: $body-color;
$navbar-light-active-color: $body-color;
$navbar-light-disabled-color: $body-color;

$grid-breakpoints: (
    xs: 0,
    sm: 699px,
    md: 991px,
    lg: 992px,
    xl: 1250px,
    xxl: 1500px,
    xxxl: 2000px
);

$container-max-widths: (
    sm: 700px,
    md: 959px,
    lg: 960px,
    xl: 1140px,
    xxl: 1380px
);

// Buttons
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

// Borders
$border-width: 1px;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
);

$border-color: #D4D4D4;

// Accordion
$accordion-padding-y: 1rem;
$accordion-padding-x: 1.25rem;
$accordion-color: rgba($white, 0.8);
$accordion-bg: rgba($white, 0.8);
$accordion-border-width: $border-width;
$accordion-border-color: rgba($white, 0.8);
/*$accordion-border-radius: $border-radius;
$accordion-inner-border-radius: subtract($accordion-border-radius, $accordion-border-width);

$accordion-body-padding-y: $accordion-padding-y;
$accordion-body-padding-x: $accordion-padding-x;*/

/*$accordion-button-padding-y: $accordion-padding-y;
$accordion-button-padding-x: $accordion-padding-x;
$accordion-button-color: $accordion-color;
$accordion-button-bg: $accordion-bg;
$accordion-transition: $btn-transition, border-radius .15s ease;*/
$accordion-button-active-bg: $white;
$accordion-button-active-color: $primary;

/*$accordion-button-focus-border-color: $input-focus-border-color;
$accordion-button-focus-box-shadow: $btn-focus-box-shadow;*/

$accordion-icon-width: 1rem;
$accordion-icon-color: $black;
$accordion-icon-active-color: $accordion-button-active-color;
$accordion-icon-transition: transform .2s ease-in-out;
$accordion-icon-transform: rotate(-90deg);

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='#{$accordion-icon-color}'><rect y='9' width='20' height='2'/><rect y='9' width='20' height='2' transform='translate(0 20) rotate(-90)'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='#{$accordion-icon-active-color}'><rect y='9' width='20' height='2' transform='translate(-4.14 10) rotate(-45)'/><rect x='9' width='2' height='20' transform='translate(-4.14 10) rotate(-45)'/></svg>");

// Forms
$form-check-input-border:             1px solid $border-color;
$form-check-input-border-radius:      0;

$form-check-input-focus-border:       $input-focus-border-color;
$form-check-input-focus-box-shadow:   $input-btn-focus-box-shadow;

$form-check-input-checked-color:          $redish;
$form-check-input-checked-bg-color:       $white;
$form-check-input-checked-border-color:   $form-check-input-border;
$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
$form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");

$form-select-bg: $input-bg;
$form-select-border-width:            $input-border-width;
$form-select-border-color:            $border-color;

$form-select-focus-border-color:      $input-focus-border-color;
$form-select-focus-width:             $input-focus-width;
$form-select-focus-box-shadow:        $input-focus-box-shadow;

$icon-path: "../img/icons/" !default;
$image-path: "../img/" !default;

@import "../node_modules/bootstrap/scss/variables";
