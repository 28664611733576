.block-links{
    padding: 0;
    padding-bottom: 1rem;
    &--header{
        border-bottom: 1px solid $gray-300;
        padding-bottom: .5rem;
        margin-bottom: .5rem;
    }
    &__item{
        margin-bottom: .5rem;
        display: flex;
        flex-wrap: wrap;
        &--link{
            flex-basis: 100%;
        }
    }
}