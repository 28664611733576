﻿@import "../../mixins.scss";

.block-list:not(.block-list-sidebar) .block-newsletter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__header {
        @include text-pipe();
        @include media-breakpoint-down(md) {
            text-align: center;
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__text {
        max-width: 60%;
        text-align: center;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
            max-width: 80%;
        }
    }

    &__form {
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(md) {
            text-align: center;
        }

        &--inline {
            .form-horizontal {

                @include media-breakpoint-up(md) {
                    display: flex;
                    justify-content: center;
                }

                .umbraco-forms-fieldset {
                    @include media-breakpoint-up(md) {
                        min-width: 100%;
                        padding-top: 0;
                        margin-right: 24px;
                    }
                }
    
                .umbraco-forms-container {
                    display: flex;
                    gap: 20px;
                    justify-content: center;
                    align-items: center;
    
                    .umbraco-forms-field {
                        width: 100%;
                        &.dataconsent {
                            @include media-breakpoint-up(md) {
                                flex: 0 0 100%;
                                max-width: 600px;
                            }
                        }
                    }
                }

                .umbraco-forms-navigation {
                    padding: 0;

                    @include media-breakpoint-down(sm) {
                        margin-top: 1rem;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;

            .form-group {
                width: 100%;
            }
        }
    }

}

.block-list-sidebar {
    .block-newsletter {
        display: block;

        .umbraco-forms-container{
            width: 100%;
        }
        
        .umbraco-forms-navigation {
            margin-top: 1rem;
        }

        &:hover {
            text-decoration: none;
            color: initial;
        }

        &__header {
            margin: 0;
            margin-bottom: 0.5rem;
            margin-left: 1.875rem;
            color: $text-color;
            @include text-pipe($sidebar: true);
        
            &::before {
                height: 52px !important;
                margin-bottom: -15px;
                z-index: 999;
            }
        }

        &__inner {
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            padding: 1.5rem 2.5rem;
            background-color: $light-gray;
        }
        &__text {
            color: $text-color;
            margin-bottom: 1rem;
        }
        &__form {
            .btn {
                width: 100%;
            }
        }
    }
}