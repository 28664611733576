.blog {
    &__item {
        display: flex;
        background-color: $light-gray;
        padding: 1.25rem;
        border: 1px solid $white;
        margin-bottom: 1rem;
        flex-wrap: wrap;
        border-radius: 20px;
        cursor: pointer;

        &--header{
            margin-top: 0;
            margin-bottom: 1rem;
            width: 100%;
        }

        &--teaser {
            color: $text-color;
            width: 100%;
        }

        &:hover {
            background-color: $gray-400;
            text-decoration: none;
        }
    }

    h2, h3, h4, h5{
        margin-top: 0;
        margin-bottom: 0;
    }
    
}