@import "../mixins.scss";

.search-results {
    margin-bottom: 2rem;

    &__empty {
        text-align: center;
        padding: 25px;

        @include media-breakpoint-up(md) {
            padding: 50px;
        }
    }

    .search-result {
        margin-bottom: 2rem;

        &__title {
            font-size: 1.25rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
        }
    }
}