.block-list-sidebar {
    .block-calendar {
        &__inner {
            border: 1px solid $border-color;
            padding: 1.5rem 2.5rem;
            border-radius: 20px;
        }

        &__header {
            margin: 0;
            margin-bottom: 0.5rem;
            margin-left: 1.875rem;
            color: $text-color;
            @include text-pipe($sidebar: true);
        
            &::before {
                height: 52px !important;
                margin-bottom: -15px;
                z-index: 999;
            }
        }
        &--link{
            float: right;
            margin-top: 1rem;
        }
        &__item{
            display: flex;
            flex-wrap: wrap;
            line-height: 1;
            margin-bottom: 1rem;
            
            &--date{
                flex-basis: 100%;
            }
        }
    }
}