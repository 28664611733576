.freelance{
    &--available{
        display: flex;
        flex-wrap: wrap;
        span{
            flex-basis: 100%;
        }
    }

    th{
        width: 25%;
        min-width: 150px;
    }
    tr{
        cursor: pointer;
        min-height: 70px;
        .freelance__name{
            display: flex;
            &::before{
                content: '';
                background-image: escape-svg(url("data:image/svg+xml,#{map-get($icons, 'caret-up')}"));
                height: 20px;
                width: 20px;
                background-repeat: no-repeat;
                margin-right: .5rem;
                background-size: 85%;
                margin-top: 7px;
            }
        }

        &.open{
            td{
                span{
                    -webkit-line-clamp: unset;
                }
            }

            .freelance__name{
                &::before{
                    background-image: escape-svg(url("data:image/svg+xml,#{map-get($icons, 'caret-down')}"));
                }
            }
        }
        
    }
    td{
        overflow: hidden;
        width: 25%;
        min-width: 150px;
        @include media-breakpoint-up(md){
            span{
                overflow: hidden;
                text-overflow: clip;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }

    .freelance-label{
        display: none;
    }

    @include media-breakpoint-down(md){
        .table>:not(:first-child){
            border: none;
        }
        thead{
            display: none;
        }
        .table{
            table-layout: fixed;
            
        }
        tr{
            display: flex;
            flex-direction: column;
            border: 1px solid $dark-blue;
            margin-bottom: 2rem;

            .freelance__name{
                &::before{
                    content: none;
                }
            }
            td{
                display: flex;
                width: 100%;
                border: none;
                flex-wrap: wrap;
                background-color: white !important;
                .freelance-label{
                    width: 100%;
                    font-weight: bold;
                    display: block;
                }
            }
        }
    }

}