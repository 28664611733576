﻿@import "../../mixins.scss";

.block-card {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;

    &:hover {
        text-decoration: none;
        color: initial;

        .block-card__inner {
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
        }
    }

    a {
        text-decoration: none;
        color: $text-color;
    }

    &__header {
        margin: 0;
        margin-bottom: 0.5rem;
        margin-left: 1.875rem;
        color: $text-color;
        z-index: 5;

        @include text-pipe($height: 52px, $x: 0);

        &::before {
            transform: translateY(15px);
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        max-width: 435px;
        @include media-breakpoint-down(md) {
            max-width: unset;
        }
        background-color: $white;
        border-radius: 20px;
        border: 1px solid $border-color;
        transition: all 500ms ease-in-out;
    }

    &__content {
        position: relative;
        padding: 1.5rem 2.5rem;
    }

    &__text {
        margin: 0;
        min-height: 100px;
        color: $text-color;
        margin-bottom: 1rem;
    }

    &__links {
        margin-top: auto;
    }

    &__image {
        max-height: 255px;
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        margin-top: -1px;
    }
}

.block-list-sidebar {
    .block-card {
        &__text {
            padding: 1.5rem 2.5rem;
            margin: 0;
        }

        &__header {
            @include text-pipe($sidebar: true);
        }

        &:hover {
            .block-card__inner {
                text-decoration: unset;
                box-shadow: unset;

                a {
                    color: $redish;
                }
            }
        }
    }
}