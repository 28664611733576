.umbraco-forms-form {
  .btn {
    white-space: nowrap;
  }

  .umbraco-forms-caption {
    margin-bottom: 1rem;
  }

  // Field types

  &.kursustilmelding {
    fieldset {
      > legend {
        @extend .h3;
        @include text-pipe($height: 40px);
      }
    }

    fieldset:nth-child(1),
    fieldset:nth-child(2) {
      .umbraco-forms-label {
        font-size: 1.25rem;
        font-weight: 500;
      }
    }

    fieldset:first-of-type + fieldset {
      > .row {
        margin-top: 4rem;
        margin-bottom: 2rem;
        margin-left: 0;
        margin-right: 0;

        @include media-breakpoint-up(md) {
          background-color: $light-gray;
          border: 1px solid $white;
          border-radius: 13px;
        }

        label {
          transform: translate(-1rem, -2rem);
          @include media-breakpoint-down(md) {
            transform: translate(0, -2rem);
          }
        }

        .umbraco-forms-container {
          @include media-breakpoint-down(md) {
            padding-left: 0;
            padding-right: 0;
            margin-bottom: 1rem;
          }
        }

        .umbraco-forms-field {
          @include media-breakpoint-down(md) {
            background-color: $light-gray;
            border: 1px solid $white;
            border-radius: 13px;
          }
        }

        .umbraco-forms-field-wrapper {
          transform: translateY(-1rem);

          @include media-breakpoint-down(md) {
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }
        }
      }
    }

    .umbraco-forms-label {
      @include media-breakpoint-up(md) {
        padding-left: 1.5rem;
      }
    }

    .richtext,
    .titleanddescription {
      .umbraco-forms-field-wrapper {
        p {
          margin: 0.25rem 0.5rem;
        }
      }

      @include media-breakpoint-down(md) {
        .umbraco-forms-label {
          padding-left: 1rem;
        }
      }
    }

    .tilmeldingsfrist {
      @include media-breakpoint-down(md) {
        margin-top: 2rem;

        .umbraco-forms-label {
          padding-left: 1rem;
        }
      }
    }

    .coursedates {
      @include media-breakpoint-down(md) {
        .umbraco-forms-label {
          padding-left: 1rem;
        }
      }

      .course-location {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }
  }

  .course-dates {
    .course-date {
      margin-left: 0;
      margin-right: 0;
      background-color: $light-gray;
      border: 1px solid $white;
      border-radius: 13px;

      .course-date__date-text {
        font-size: 0.8em;

        span {
          font-weight: 600;
        }
      }
      .course-date__location,
      .course-date__teacher {
        display: flex;
        margin-bottom: 0.5rem;

        > svg {
          width: 1.25rem;
          height: 1.25rem;
          margin-right: 0.5rem;
          flex-shrink: 0;
        }

        a {
          color: inherit;
        }
      }
    }
  }
}

.form-group {
  min-height: 78px;
}

#b6eb7e81-2d90-4d55-debd-76f0e7775280 {
  .umbraco-forms-container:nth-child(2) {
    display: flex;
  }
  .postnr {
    width: 35%;
    margin-right: 1rem;
  }
  .by {
    flex-grow: 1;
  }
}

.jegtilmeldermigsomprivatperson,
.klikherforatangivesærskiltfaktureringsadresse {
  .umbraco-forms-field-wrapper {
    min-height: 52px;
    display: flex;
    align-items: center;
  }
}

#0675d0e0-6455-49ff-a8af-3322973c047e {
  margin-bottom: 2rem;
}

@include media-breakpoint-down(md) {
  #b6eb7e81-2d90-4d55-debd-76f0e7775280 {
    .row {
      .umbraco-forms-container {
        &:first-child {
          order: 2;
        }
      }
    }
  }
}

