.block-related-course{
    &__header{
        @include text-pipe($height: 52px);
        margin-bottom: .5rem;
        &::before{
            margin-bottom: -20px;
            z-index: 999;
            margin-left: 2rem;
        }
    }

    .sub-archive-grid{
        grid-template-columns: repeat(2,1fr);

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .sub-archive-card{
        &:hover {
            box-shadow: 0 0 20px 0 rgba($black, 0.2);
        }
        &__link{
            @include arrow-link($color: $secondary-hover);
        }
        &__header{
            margin-bottom: 1rem;
        }
        &__duration{
            color: $redish;
        }
        &__price{
            margin-bottom: 1rem;
        }
    }
}