.accordion {
    margin-bottom: 0;

    &-button {
        color: $black;
        padding: 0;

        &:after {
            background-image: url("#{$icon-path}/plus-sign.svg");
            right: 10px;

            @include media-breakpoint-up(sm) {
                right: 28px;
            }

            position: absolute;
        }

        &:not(.collapsed)::after {
            background-image: url("#{$icon-path}/minus-sign.svg");
            transform: rotate(-180deg);
        }

        &:not(.collapsed) {
            background-color: #EFF4F7;
            border-top-left-radius: 13px !important;
            border-top-right-radius: 13px !important;
        }
    }

    &-item {
        border-top: 1px solid $input-border-color;
        border-bottom: 1px solid $input-border-color;
        justify-content: center;
        vertical-align: middle;
        padding-top: .3rem;
        padding-bottom: .3rem;
        background-color: transparent;
        border-left: 1px solid transparent;

        &:not(:first-of-type) {
            border-top: 1px solid $input-border-color;
        }

        &:not(:last-of-type) {
            border-bottom: none;
        }

        button{
            &:focus {
                outline: none;
                box-shadow: none;
              }
        }
    }

    &-header {
        margin-left: 1.875rem;
        margin-right: 1.875rem;
        color: $dark-blue;
        padding-top: 1rem;
        padding-bottom: 1rem;
        margin-top: 0;

        @include text-pipe();

        &::before {
            left: 0;
            position: absolute;
            height: 40px;
        }
    }

    &-body {
        background-color: #EFF4F7;
        border-bottom-left-radius: 13px;
        border-bottom-right-radius: 13px;
        margin-top: -5px;
        position: relative;
        padding-top: 0;
        padding-left: 1.5rem;
    }

    &-list__content {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        position: relative;

        .btn {
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    .course-program {
        background-color: $light-gray;
        padding: .5rem;

        &__item {
            margin-left: 0;
            margin-bottom: 1rem;

            &--teacher {
                display: flex;
                margin-top: 1rem;

                &__content {
                    margin-left: 1rem;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                }
            }
        }
    }
}