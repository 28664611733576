﻿@import "../mixins.scss";

.archive-grid,
.sub-archive-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: $grid-gutter-width;
    grid-row-gap: $grid-gutter-width;
    grid-template-rows: auto 1fr;

    @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(md) {
        grid-template-columns: repeat(1, 1fr);
    }
    &.main-archive {
        grid-template-columns: repeat(3, 1fr);
    
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

.archive-card {
    display: block;
    position: relative;
    transition: all 500ms ease-in-out;
    overflow: hidden;
    border-radius: 8px;
    max-height: 240px;

    img {
        width: 100%;
        z-index: 1;
        object-fit: cover;
        object-position: center;
    }

    &::before {
        content: "";
        position: absolute;
        z-index: 2;
        left: 0;
        bottom: 0;
        background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .4)), to(rgba(0, 0, 0, .4)));
        background-image: linear-gradient(rgba(0, 0, 0, .4), rgba(0, 0, 0, .4) 100%);
        height: 100%;
        width: 100%;
        border-radius: 8px;
    }

    &__header {
        position: absolute;
        z-index: 3;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 5%;
        text-align: center;
        word-break: break-word;
        color: $white;
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        font-weight: $headings-font-weight;
        font-size: $h3-font-size;
        line-height: 1.2;

        @include media-breakpoint-down(md) {
            font-size: $h2-font-size;
        }
    }

    &:hover {
        box-shadow: 0 0 20px 0 rgba($black, 0.2);
    }
}


.sub-archive-card {
    display: block;
    background-color: $white;
    border-radius: 20px;
    border: 1px solid $border-color;
    position: relative;

    &:hover {
        text-decoration: none;
        color: initial;

        .archive-card__inner {
            box-shadow: 0 0 20px 0 rgba($black, 0.2);
        }
    }

    a {
        color: $text-color;
        text-decoration: none;
    }

    &__header {
        margin: 0;
        padding: 1.5rem 2.5rem 0 2.5rem;
        margin-bottom: 0.45rem;
        color: $text-color;
    }

    &__inner {
        display: block;
        transition: all 500ms ease-in-out;
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 0 2.5rem 1.5rem 2.5rem;
    }

    &__text {
        margin-top: 1rem;
        color: $text-color;
    }

    &__link {
        margin-top: 1rem;
    }

    &__image {
        max-height: 255px;
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        margin-top: -1px;
    }

    &__btn {
        display: flex;
        margin: 0 auto;
        position: absolute;
        transform: translate(-50%, 50%);
        left: 50%;
        bottom: 0;
    }

    &__offer {
        top: 1rem;
        right: 1rem;
        color: $white;
        position: absolute;
        padding: 1rem 1.5rem;
        max-width: 340px;
        text-align: center;
        display: block;
        font-weight: 500;

        &::before {
            content: "";
            display: block;
            background-color: $redish;
            border-radius: 20px;
            transform: skew(-10deg);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        .offer-text {
            display: block;
            position: relative;
        }
    }
}

.location-archive {
    &__header {
        &--link {
            color: inherit;
        }
    }
}