﻿@import "../mixins.scss";
@import "../base/icons";

$social-icons: (
  "facebook": #4267B2,
  "linkedin": #0077B5
);

.footer {
    color: $dark-blue;
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 6.5rem;
    padding-bottom: 3.5rem;

    a {
        text-decoration: none;
        color: $dark-blue;

        &:hover {
            color: $redish;
        }
    }

    .row {
        justify-content: space-between;
        gap: 1rem;

        @include media-breakpoint-up(md) {
            --bs-gutter-x: 60px;
            gap: 0;
        }
    }
}

.contact-info {
    @include media-breakpoint-up(md) {
        justify-content: space-between;
        flex-direction: column;
        display: flex;
    }

    @include media-breakpoint-down(md) {
        display: flex;
        flex-wrap: wrap;

        &__address--inner {
            flex: 0 0 50%;
        }
    }

    &__socials {
        display: flex;
        margin-top: 25px;

        .social-icon {
            transition: all .15s ease-in-out;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 50%;
            display: block;
            width: 100%;
            height: 100%;
        }

        @each $social in map-keys($social-icons) {
            .#{$social} {
                background-color: $lighter-gray;
                transition: all .15s ease-in-out;
                display: block;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                
                .social-icon {
                    background-image: escape-svg(url("data:image/svg+xml,#{map-get($icons, $social + '-solid')}"));
                }
            }
        }
    }

    &__action {
        &:not(:last-child) {
            margin-right: 0.5rem;
        }
    }

    &__link {
        @each $social in map-keys($social-icons) {
            &.#{$social} {
                &:hover, &:focus {
                    background-color: map-get($social-icons, $social);
                    
                    .social-icon {
                        filter: invert(1);
                    }
                }
            }
        }
    }
}

.footer--links ul {
    @include media-breakpoint-down(md) {
        display: flex;
        flex-wrap: wrap;
        margin-top: 2rem;

        li {
            flex: 0 0 50%;
            margin-bottom: 0.5rem;
        }
    }
}