﻿@import "../../mixins.scss";

.homepage .block-testimonials {
    margin-left: 1.875rem;
}

.block-testimonials {
    display: block;

    &:hover {
        text-decoration: none;
        color: initial;
    }

    &__header {
        @include text-pipe();
    }

    &__inner {
        display: flex;
        flex-direction: column;
    }

    &__text {
        font-weight: 300;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__name {
        margin-top: 1rem;
    }

    &__position {
        font-weight: 300;
        font-style: italic;
    }

    .arrow-link {
        .bg-dark-blue & {
            @include arrow-link($color: $white);
        }
    }
        
}
.block-list:not(.block-list-sidebar) .block-testimonials {
    &__header,&__text,&__name,&__position {
        color: $white;
    }
}

.block-list-sidebar {
    .block-testimonials {
        &__inner {
            padding: 1.5rem 2.5rem;
            border-radius: 20px;
            background-color: $dark-blue;
            color: $white;
            .arrow-link{
                @include arrow-link($color: $white);
            }
        }

        &__header {
            margin: 0;
            margin-bottom: 0.5rem;
            margin-left: 1.875rem;
            color: $text-color;
            @include text-pipe($sidebar: true, $x: 0);
        
            &::before {
                height: 52px !important;
                margin-bottom: -15px;
                z-index: 999;
            }
        }
    }
}