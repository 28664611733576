.quiz{
    .text-danger{
        color: $redish;
    }
    .text-success{
        color: $green;
    }
    &__before{
        margin-bottom: 1rem;
    }
    &__after{
        margin-bottom: 1rem;
    }
    &__question{

        &:not(:first-child){
            margin-top: 1rem;
        }
        &--incorrect{
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 1rem;
            margin-top: .5rem;

            &-text{
                flex-basis: 100%;
            }
        }
        &--correct-answers{
            color: #005089;
        }
        &--header{
            margin-bottom: .5rem;
            margin-top: 2rem;
        }
    }
    &__controls{
        margin-top: 1rem;
    }
}