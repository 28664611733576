﻿@import "../../mixins.scss";

.block-discount {
    display: flex;
    color: $white;

    .homepage & {
        margin-left: 1.875rem;
    }

    &__header {
        @include text-pipe();
    }

    &__text {
        position: relative;
    }

    &__disclaimer {
        display: block;
        margin-top: 1rem;
        font-weight: 300;
        font-style: italic;
    }

    &__box-one,
    &__box-two {
        max-width: 480px;
        padding: 1.5rem 2.5rem;
        text-align: center;
        font-weight: $font-weight-semi-bold;
        position: relative;

        @include media-breakpoint-down(sm) {
            transform: translateX(-1rem);
        }

        &::before {
            content: "";
            display: block;
            border-radius: 20px;
            transform: skew(-10deg);
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        .offer-text {
            display: block;
            transform: skew(10deg); //unskew
        }
    }

    &__box-one {
        color: $text-color;

        &::before {
            background-color: $white;
        }
    }

    &__box-two {
        color: $white;
        margin-top: -0.75rem;

        @include media-breakpoint-up(sm) {
            margin-left: 2rem;
        }

        &::before {
            background-color: $redish;
        }
    }

}