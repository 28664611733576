.block-text {
  padding-top: 1rem;
  padding-bottom: 1rem;
  &__header {
    margin: 0;
  }
  p{
    margin-bottom: 1rem;
  }

  h3,
  h4,
  h5 {
    margin-top: 2rem;
    margin-bottom: 0rem;
  }
}
