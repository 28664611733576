.testimonial-item{
    &__position{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1rem;
        &--label{
            flex-basis: 100%;
            font-weight: bold;
        }
        &--value{
            flex-basis: 100%;
        }
    }
    &__background{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1rem;
        &--label{
            flex-basis: 100%;
            font-weight: bold;
        }
        &--value{
            flex-basis: 100%;
        }
    }
    &__evaluation{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1rem;
        &--label{
            flex-basis: 100%;
            font-weight: bold;
        }
        &--value{
            flex-basis: 100%;
        }
    }
}